import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignUp, apiSignInAdmin, apiChangePassword } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY, APP_XUSER_CODE } from 'constants/app.constant'
import { APPLICANT, EMPLOYER } from 'constants/roles.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import jwt_decode from "jwt-decode";

function useAuth() {

	const dispatch = useDispatch()

	const navigate = useNavigate()

	const query = useQuery()

	const { token, signedIn } = useSelector((state) => state.auth.session);
	const user = useSelector((state) => state.auth.user)

	const signIn = async (values) => {
		try {
			const resp = await apiSignIn(values);

			if (resp?.data) {
				const token = resp.data
				const tokenDecoded = jwt_decode(token);
				const user = { ...tokenDecoded, authority: [...tokenDecoded.roles, APPLICANT] }
				delete user.roles;
				dispatch(onSignInSuccess(token))
				dispatch(setUser(user));
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
				return {
					status: 'success',
					message: '',
					data: resp.data
				}
			}

			return {
				status: 'failed',
				message: 'Unknown error',
				resp
			}
		} catch (errors) {
			if (errors?.response?.status === 403) {
				return {
					status: 'failed',
					message: 'Usuario o contraseña incorrectos',
					errors
				}
			}
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString(),
				errors
			}
		}
	}

	// sign in for admin
	const signInAdmin = async (values) => {
		try {
			const resp = await apiSignInAdmin({ ...values, app: APP_XUSER_CODE });

			if (resp.status === 200) {
				const token = resp.data.token
				const user = jwt_decode(token);

				dispatch(onSignInSuccess(token))
				dispatch(setUser({
					id: user.nameid,
					name: user.Nombre,
					username: user.actort,
					email: user.actort,
					firstSurname: user.PrimerApellido,
					secondSurname: user.SegundoApellido,
					authority: [...user.role, EMPLOYER],
					exp: user.exp,
					permitedApp: user.PermitedApp,
					employeeCode: user.CodigoEmpleado,
				}));
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.adminEntryPath)
				return {
					status: 'success',
					message: '',
					data: resp.data
				}
			}
		} catch (errors) {
			if (errors?.response?.status === 403) {
				return {
					status: 'failed',
					message: 'Usuario o contraseña incorrectos',
					errors
				}
			}
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString(),
				errors
			}
		}
	}

	const signUp = async (values) => {
		try {
			const resp = await apiSignUp(values)
			if (resp.status === 200) {
				const token = resp.data.token
				const tokenDecoded = jwt_decode(token);
				const user = { ...tokenDecoded, authority: [...tokenDecoded.roles, APPLICANT], resumeId: '' }
				delete user.roles;
				dispatch(onSignInSuccess(token))
				dispatch(setUser(user));
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : '/resume/edit')

				return {
					status: 'success',
					message: '',
					resp
				}
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString()
			}
		}
	}

	const handleSignOut = () => {
		const isAdmin = isAdministrator();
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))

		if (isAdmin) {
			navigate(appConfig.adminUnAuthenticatedEntryPath)
			return;
		}

		navigate(appConfig.unAuthenticatedEntryPath)
	}

	const signOut = async () => {
		handleSignOut()
	}

	const isAdministrator = () => {
		return user.permitedApp === APP_XUSER_CODE && user.authority.includes('admin');
	}

	const changePassword = async (data) => {
		try {
			const resp = await apiChangePassword(user.id, data)
			if (resp.status === 200) {
				return {
					status: 'success',
					message: '',
					resp
				}
			}

			return {
				status: 'failed',
				message: resp?.data?.message || 'Unknown error',
				resp
			}
		} catch (errors) {
			if (errors?.response?.status === 403) {
				return {
					status: 'failed',
					message: 'Contraseña actual incorrecta',
					errors
				}
			}
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString(),
				errors
			}
		}
	}

	return {
		authenticated: token && signedIn,
		isAdministrator: isAdministrator(),
		signIn,
		signUp,
		signOut,
		signInAdmin,
		changePassword,
	}
}

export default useAuth