const appConfig = {
    apiPrefix: '/api',
    adminEntryPath: '/admin/home',
    adminUnAuthenticatedEntryPath: '/admin/sign-in',
    authenticatedEntryPath: '/inicio',
    unAuthenticatedEntryPath: '/trabaja-con-nosotros',
    tourPath: '/',
    enableMock: false,
    locale: 'es',
}

export default appConfig