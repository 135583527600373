import ApiService from './ApiService'
import { REQUEST_HEADER_AUTH_KEY, URL_AUTH as URL, URL_XUSER } from 'constants/api.constant'

export async function apiSignIn (data) {
    return ApiService.fetchData({
        url: `${URL}/usuario/login`,
        method: 'post',
        data
    })
}

export async function apiSignInAdmin (data) {
    return ApiService.fetchData({
        url: `${URL_XUSER}/AuthManagement`,
        method: 'post',
        data
    })
}

export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: `${URL}/usuario`,
        method: 'post',
        data
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data
    })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: `${URL}/usuario/recuperacionpassword`,
        method: 'post',
        data
    })
}

export async function apiResetPassword (data, token) {
    return ApiService.fetchData({
        url: `${URL}/usuario/restablecerpassword`,
        method: 'post',
        headers: {
            [REQUEST_HEADER_AUTH_KEY] : token
        },
        data
    })
}

export async function apiChangePassword (id, data) {
    return ApiService.fetchData({
        url: `${URL}/usuario/cambiarpassword/${id}`,
        method: 'post',
        data
    })
}


