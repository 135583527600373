export const TOKEN_TYPE = 'Bearer '
export const REQUEST_HEADER_AUTH_KEY = 'Authorization'

// export const URL = 'http://172.20.1.59:1337/api/v1'
// export const URL = 'http://localhost:1337/api/v1'
export const URL = "https://empleos-api.cmsiglo21.app/api/v1"

// export const URL_AUTH = 'http://172.20.1.63:3001/api/v1'
// export const URL_AUTH = 'http://172.20.6.46:3001/api/v1'
export const URL_AUTH = "https://usuarios-api.cmsiglo21.app/api/v1"

export const URL_XUSER = 'https://xuser-api.cmsiglo21.app/api'